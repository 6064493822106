<template>
  <main>
    <div id="baner-orange"><h1>404</h1></div>
    <v-container id="textOnBaner">
      <v-row>
        <v-col>
          <v-card elevation="5">
            <v-card-title>Nie ma takiej strony! </v-card-title>
            <v-card-text
              >Sprawdź, czy wpisałeś(aś) prawidłowy adres i spróbuj
              ponownie.</v-card-text
            >
          </v-card>
        </v-col>
      </v-row>
      <v-card class="mt-4 color-card bg-orange" elevation="0" dark>
        <v-card-text>Zaraz przekierujemy Cię do strony głównej.</v-card-text>
      </v-card>
    </v-container>
  </main>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  methods: {
    ...mapMutations(["changeNavVisibility", "changeMinMode"]),
  },
  mounted() {
    this.changeNavVisibility(false);
    this.changeMinMode(true);
    setTimeout(() => {
      this.$router.replace({ name: "Home" });
    }, 5000);
  },
  destroyed() {
    this.changeNavVisibility(true);
    this.changeMinMode(false);
  },
};
</script>

<style>
#baner-orange {
  width: 100vw;
  height: 350px;
  background: linear-gradient(0deg, rgb(223, 90, 2) 0%, rgb(224, 183, 0) 100%);
  color: white;
}

.bg-orange {
  background: linear-gradient(45deg, rgb(223, 90, 2) 0%, rgb(224, 183, 0) 100%);
  color: white;
}

#baner-orange h1 {
  text-align: center;
  display: block;
  font-size: 8rem;

  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

@media (max-width: 1200px) {
  #baner-orange h1 {
    font-size: 72px;
  }
}
</style>